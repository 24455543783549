import { useState } from 'react';
import './App.scss';
import render1 from './img/render1.png';

enum Lang {
  "CN" = "cn",
  "EN" = "en"
}

interface Localization {
  header: string,
  p1: string,
  p2: string,
  p3: string,
  p4: string,
  fundLink: string,
}

const enLocalization: Localization = {
  header: 'Blue Shackles',
  p1: 'As sexual minorities, as women, as outsiders, we have no story, we just want to invite you to indulge in pain, bondage, and abandon.',
  p2: "We use blue ropes to attempt to weave a cruel world: entangled, dense, inescapable. In fact, the real world is crueler, thicker, heavier, more painful, and more unimaginable to penetrate with one's body: you are a woman, taught from a young age to be obedient, stable, good at sacrificing, loyal. You are prepared to become a wife, a supporting role, a comforter. You are forbidden from being curious, lascivious, ambitious, and capricious. You avoid becoming a threat to the stable framework. If something brings you pleasure, it is likely absurd, lascivious, rebellious, something to be restrained, and immoral. You, like the world, are contradictory.",
  p3: 'Steel frames are wrapped, threatened, and tangled by impermeable ropes of desire, standing tall and majestic like markers in the desert, yet providing whips to another group. You perceive them as massive as a sweeping sandstorm, yet unpredictable, leaving you nowhere to hide. Desires traverse "them," and "they" traverse your body. ',
  p4: 'We invite you to bravely enjoy the game with us. In this eternal blue forest, one day we will forget the dense terror and fully immerse ourselves in this crazy contradiction, adventuring with our own small orbs of light. It is light, it is flame, the indestructible world that will ultimately become our eternal paradise.',
  fundLink: "Funderaising link"
}

const chLocalization: Localization = {
  header: '束缚之莲',
  p1: '做为性少数，作为女性，作为外来者，我们没有故事，我们只想邀请你享用疼痛、束缚与放纵。',
  p2: '我们用蓝色绳索试图编造一个残酷的世界：纠缠、密匝匝、无处可逃，事实上，真实的那一个世界比这个残酷，它更厚更重，更痛，更难让人想象可以去用身体穿透它：你是一个女人，你从小被教育要顺从、稳定、善于牺牲、从一而终，你要准备将自己变成妻子、配角、安抚者，你不能好奇、淫荡、野心勃勃、情绪无常，你避免成为稳定框架下的威胁因子，如果某种东西让你感到愉悦，那或许是荒唐的、淫荡的、大逆不道的、需要克制的，是背德的。你和世界一样是矛盾的。',
  p3: '钢架被密不透风的欲望绳索缠绕、威胁、凌乱、岌岌可危，道德钢架威严耸立，它是仿佛是穿越黄沙的标杆，却又给另一群人提供鞭子，你发道其之大如同掀翻一切的沙尘暴、却又变幻莫测让你无处遁形，欲望在“它们”中穿行，“它们”也在穿越你的身体。',
  p4: '我们邀请你一起勇敢地享受游戏，我们在这永恒的蓝色密林，总有一天也会忘记这密匝的恐怖，能够全然投身于这个疯狂的矛盾中，跟自己的光之小球一道冒险，它是光，是火焰，我们不能尽然摧毁的那一个世界，终将会成为我们自己永恒的乐园。',
  fundLink: "众筹链接"
}

function App() {
  const [lang, setLang] = useState<Lang>(Lang.EN)

  const { header, p1, p2, p3, p4, fundLink } = lang === Lang.CN ? chLocalization : enLocalization;

  return (
    <div className="App">
      <div className='header'>
        <div className={`lang ${lang === Lang.CN ? 'active-lang' : ''}`} onClick={() => setLang(Lang.CN)} >文</div>
        <div>|</div>
        <div className={`lang ${lang === Lang.EN ? 'active-lang' : ''}`} onClick={() => setLang(Lang.EN)}>en</div>
      </div>
      <div className='container'>
        <div className='layout-text'>
          <div className="header-1">{header}</div>
          <div className='paragraph-1'>{p1}</div>
          <div className='paragraph-1'>{p2}</div>
          <div className='paragraph-1'>{p3}</div>
          <div className='paragraph-1'>{p4}</div>
          <div className='paragraph found-link'>{fundLink}: http://placeholder</div>
        </div>
        <div className='layout-img'>
          <img src={render1} alt="p1" />
        </div>
      </div>
    </div>
  );
}

export default App;
